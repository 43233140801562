/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, lazy, Suspense, useContext,
} from 'react';
import { Redirect } from 'react-router-dom';
import _debounce from 'lodash.debounce';
import '../assets/css/Layout.css';

import Loader from '../components/Loader/Loader';
import { Utils } from '../context/UtilsContext';
import { isLoggedIn, logout, decodedToken } from '../config/token';

const Header = lazy(() => import('../components/Nav/Header'));
const Sidebar = lazy(() => import('../components/Nav/Sidebar'));
const Title = lazy(() => import('../components/Nav/Title'));
const Footer = lazy(() => import('../components/Nav/Footer'));

const Layout = (Component) => function Wrapper(props) {
  const [isMobile, setIsMobile] = useState(false);
  const { loading } = useContext(Utils);
  const divProp = !isMobile ? {} : { className: 'mobile' };
  const [width, setWidth] = useState(window.innerWidth);
  let isMounted = false;
  const { username, type, access } = decodedToken();
  const userDetails = { username, type, access };
  useEffect(() => {
    isMounted = true;
    const handleResize = _debounce(() => {
      if (isMounted) {
        setWidth(window.innerWidth);
        if (window.innerWidth < 999) setIsMobile(true);
        else setIsMobile(false);
      }
    }, 250);
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      isMounted = false;
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  // uncomment this to enable route security by checking expiration dates of tokens
  if (!isLoggedIn() || !access.wims) {
    logout();
    return (<Redirect to="/" />)
  }

  if (!props.match.isExact) {
    return <Redirect to="/error" />
  }

  return (
    <Suspense>
      {loading
        ? <Loader />
        : null}
      <div {...divProp}>
        <Header
          {...props}
          setIsMobile={setIsMobile}
          isMobile={isMobile}
          width={width}
          username={userDetails.username}
        />
        <Sidebar
          {...props}
          setIsMobile={setIsMobile}
          isMobile={isMobile}
          width={width}
        />
        <div className="wrapper">
          <Title {...props} />
          <Component {...props} />
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </Suspense>
  )
}

export default Layout
