import React, {
  createContext,
  useReducer,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  GET_POLIST,
  UPDATE_POLIST,
  GET_RRLIST,
  DELETE_RR,
  reducer,
} from './ReceivingReducer';
import { headers } from '../config/token';
import { displayErrors, displayNotification } from '../config/display';

export const RecievingContext = createContext({});
const API = process.env.REACT_APP_API_URL;
const ReceivingProvider = ({ children }) => {
  const [receivingState, dispatch] = useReducer(reducer, {
    poList: [],
    supplierOption: [],
  })

  const getPoList = (setLoading, filter = {}, recordCount = 500) => {
    setLoading(true);
    let filterParams = '';
    if (filter.supplier) filterParams += `&supplier=${filter.supplier}`

    axios.get(`${API}wrms/polist?recordCount=${recordCount}${filterParams}`, headers())
      .then((res) => {
        const { poList, supplierOption } = res.data;
        dispatch({
          type: GET_POLIST,
          payload: {
            poList,
            supplierOption,
          },
        })
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
      })
  }

  const getPoItems = (id) => axios.get(`${API}wrms/polist/${id}`, headers())
    .then((res) => {
      const { poItems } = res.data;
      return poItems;
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Server Error');
    })

  const getPoInvoice = (id) => axios.get(`${API}wrms/invoices/${id}`, headers())
    .then((res) => {
      const { invoiceList, rrseries } = res.data;
      return { invoiceList, rrseries };
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Server Error');
    })

  const addReceivedDetailsToInvoice = (values) => axios.post(`${API}wrms/invoices`, values, headers())
    .then((res) => {
      const { newPo, message } = res.data;
      dispatch({
        type: UPDATE_POLIST,
        payload: {
          newPo,
        },
      });
      displayNotification('success', message);
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Server Error');
    })

  const getReceivingReport = (setLoading, filter = {}, recordCount = 500) => {
    setLoading(true);
    let filterParams = '';
    if (filter.supplier) filterParams += `&supplier=${filter.supplier}`

    axios.get(`${API}wrms/receivingreport?recordCount=${recordCount}${filterParams}`, headers())
      .then((res) => {
        const { rrList, supplierOption } = res.data;
        dispatch({
          type: GET_RRLIST,
          payload: {
            rrList,
            supplierOption,
          },
        })
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const deleteReceivingReport = (id, setLoading) => {
    setLoading(true);

    axios.delete(`${API}wrms/receivingreport/${id}`, headers())
      .then((res) => {
        const { message } = res.data;
        dispatch({
          type: DELETE_RR,
          payload: {
            id,
          },
        })
        displayNotification('success', message);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const printRrPDF = (id, setLoading) => {
    setLoading(true);
    axios({
      url: `${API}wrms/receivingreport/exports/${id}`,
      method: 'get',
      responseType: 'blob',
      ...headers(),
    })
      .then((res) => {
        displayNotification('success', 'File successfully generated');
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [res.data],
          { type: 'application/pdf' });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const printRtvPDF = (id, setLoading) => {
    setLoading(true);
    axios({
      url: `${API}wrms/rtv/exports/${id}`,
      method: 'get',
      responseType: 'blob',
      ...headers(),
    })
      .then((res) => {
        displayNotification('success', 'File successfully generated');
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [res.data],
          { type: 'application/pdf' });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  return (
    <RecievingContext.Provider
      value={{
        receivingState,
        getPoList,
        getPoItems,
        getPoInvoice,
        addReceivedDetailsToInvoice,
        getReceivingReport,
        deleteReceivingReport,
        printRrPDF,
        printRtvPDF,
      }}
    >
      {children}
    </RecievingContext.Provider>
  )
}
ReceivingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ReceivingProvider
