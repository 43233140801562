import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, Row, Col, Button, Input, Select,
} from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

const SearchAndRecordFilter = ({
  filter, setFilter, refreshContent, exportRecordToCsv,
}) => (
  <Collapse style={{ margin: '10px 0 10px 0' }} defaultActiveKey={[1]}>
    <Panel header="SEARCH AND RECORD/S" key="1">
      <Row gutter={16}>
        <Col lg={{ span: 6 }}>
          <span>Record/s limit :</span>
          <Input.Search
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            placeholder="Input search index"
          />
        </Col>
        <Col lg={{ span: 6 }}>
          <span>Record/s limit :</span>
          <Select
            defaultValue={500}
            onChange={(throttle) => {
              refreshContent(throttle);
              setFilter({ ...filter, throttle });
            }}
            style={{ width: '100%' }}
          >
            <Option value={500}>500 records</Option>
            <Option value={1000}>1000 records</Option>
            <Option value={2000}>2000 records</Option>
            <Option value={5000}>5000 records</Option>
            <Option value={10000}>10000 records</Option>
            <Option value={999999}>All records</Option>
          </Select>
        </Col>
        <Col lg={{ span: 6 }}>
          <br />
          <Button
            onClick={() => refreshContent()}
            className="bl-cl"
            icon="sync"
            block
          >
            Refresh Content
          </Button>
        </Col>
        {exportRecordToCsv && (
          <Col lg={{ span: 6 }}>
            <br />
            <Button
              onClick={() => exportRecordToCsv()}
              className="bl-cl"
              icon="file-excel"
              block
            >
              Export to CSV
            </Button>
          </Col>
        )}
      </Row>
    </Panel>
  </Collapse>
)
SearchAndRecordFilter.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilter: PropTypes.func.isRequired,
  refreshContent: PropTypes.func.isRequired,
  exportRecordToCsv: PropTypes.func,
}

SearchAndRecordFilter.defaultProps = {
  exportRecordToCsv: null,
}
export default SearchAndRecordFilter
