import React, { useState, useContext } from 'react'

import * as Yup from 'yup';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import { Button, notification } from 'antd';
import Loader from '../Loader/Loader';
import { AntInput, AntPassword } from '../AntField';
import { API, redirectPath } from '../../config/config';
import { setToken } from '../../config/token';
import { Utils } from '../../context/UtilsContext';


const defaultValues = {
  username: '',
  password: '',
}

const SubmitHandler = (values, setSubmitting, setValues, submitCount, addSubmitCount, setLoading) => {
  if (submitCount[values.username] && submitCount[values.username] > 2) {
    notification.error({
      message: 'You have tried 3 times',
    });
    setSubmitting(false);

    if (submitCount[values.username] === 3) {
      setTimeout(() => {
        delete submitCount[values.username];
        addSubmitCount(submitCount);
      }, 120000);
    }
    return false;
  }
  setLoading(true);

  axios.post(`${API}login/wims`, values)
    .then((res) => {
      setToken(res.data.access_token);
      window.location.href = redirectPath;

      setSubmitting(false);
      setLoading(false);
    })
    .catch((err) => {
      if (err.response) {
        notification.error({
          message: 'Invalid username or password',
        });
        values.password = '';
        setValues(values);

        if (submitCount[values.username]) {
          submitCount[values.username] += 1;
          addSubmitCount(submitCount);
        } else {
          submitCount[values.username] = 1;
          addSubmitCount(submitCount);
        }
      }

      setSubmitting(false);
      setLoading(false);
    })
}

const validate = Yup.object().shape({
  username: Yup.string()
    .required('Username is required')
    .min(2, 'Username should be atleast 2 characters')
    .max(12, 'Username should not exceed 12 characters'),
  password: Yup.string()
    .required('Password is required')
    .min(3, 'Password should be atleast 3 characters')
    .max(12, 'Password should not exceed 12 characters'),
})

function LoginForm() {
  const { loading, setLoading } = useContext(Utils);
  const [submitCount, addSubmitCount] = useState({});
  return (
    <Formik
      validationSchema={validate}
      onSubmit={(values, { setSubmitting, setValues }) => SubmitHandler(values, setSubmitting, setValues, submitCount, addSubmitCount, setLoading)}
      initialValues={defaultValues}
    >
      {({ values, isSubmitting }) => (
        <Form className="form-login">
          {
            loading
              ? <Loader />
              : null
          }
          <span className="form-title">ACCOUNT LOGIN</span>
          <Field
            component={AntInput}
            name="username"
            value={values.username}
            autoComplete="off"
            size="large"
            placeholder="Username"
            hasFeedback
            autoFocus
          />

          <Field
            component={AntPassword}
            name="password"
            value={values.password}
            autoComplete="off"
            size="large"
            placeholder="Password"
            hasFeedback
          />
          <Button size="large" htmlType="submit" disabled={isSubmitting} block>SIGN IN</Button>
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
