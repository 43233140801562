import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, Row, Col, Button, Select,
} from 'antd';

const { Panel } = Collapse;
const { Option } = Select;
const Filter = ({
  filter, setFilter, onResetFilter, onFilter, supplierOption,
}) => (
  <Collapse style={{ margin: '10px 0 10px 0' }} defaultActiveKey={[0]}>
    <Panel header="ADVANCE FILTER" key="1">
      <Row gutter={16}>
        <Col lg={{ span: 6 }}>
          <span>Record/s limit :</span>
          <Select
            style={{ width: '100%' }}
            onChange={(supplier) => setFilter({ ...filter, supplier })}
            value={filter.supplier}
          >
            <Option value={null}>All</Option>
            {supplierOption.map((supplier) => (
              <Option key={supplier.id} value={supplier.id}>{supplier.supplierName}</Option>
            ))}
          </Select>
        </Col>
        <Col lg={{ span: 6 }}>
          <br />
          <Button icon="filter" onClick={onFilter} className="bl-cl" block>Filter</Button>
        </Col>
        <Col lg={{ span: 6 }}>
          <br />
          <Button className="bl-cl" onClick={onResetFilter} icon="undo" block>Reset</Button>
        </Col>
      </Row>
    </Panel>
  </Collapse>
)
Filter.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilter: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  supplierOption: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default Filter
