const searchArray = (search, record = [], cols = []) => record.filter((data) => {
  let counter = 0;
  cols.forEach((col) => {
    if (Object.prototype.hasOwnProperty.call(data, col)) {
      if (data[col] && data[col].toUpperCase().indexOf(search.toUpperCase()) !== -1) counter += 1;
    }
  })
  if (counter > 0) return true;

  return false;
})

export default searchArray;
