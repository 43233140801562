// actions
export const GET_POLIST = 'GET_POLIST';
export const UPDATE_POLIST = 'UPDATE_POLIST';
export const GET_RRLIST = 'GET_RRLIST';
export const DELETE_RR = 'DELETE_RR';

export const reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
  case GET_POLIST:
    return {
      ...state,
      poList: payload.poList,
      supplierOption: payload.supplierOption,
    }
  case UPDATE_POLIST:
    return {
      ...state,
      poList: [
        ...state.poList.slice(0, state.poList.findIndex((po) => po.id === payload.newPo.id)),
        payload.newPo,
        ...state.poList.slice(state.poList.findIndex((po) => po.id === payload.newPo.id) + 1),
      ],
    }
  case GET_RRLIST:
    return {
      ...state,
      rrList: payload.rrList,
      supplierOption: payload.supplierOption,
    }
  case DELETE_RR:
    return {
      ...state,
      rrList: state.rrList.filter((rr) => rr.id !== payload.id),
    }
  default:
    return state;
  }
}
