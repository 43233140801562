import React from 'react';

import { Redirect } from 'react-router-dom';
import { redirectPath } from '../config/config';
import { isLoggedIn } from '../config/token';
import LoginForm from '../components/Login/LoginForm';

const Login = () => {
  if (isLoggedIn()) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <div className="logincontainer">
      <LoginForm />
    </div>
  );
};

export default Login;
