import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Table,
  Button,
  Tooltip,
  Icon,
} from 'antd';
import Confirm from '../Confirm';


const ReceivingReportTable = (props) => {
  const {
    rrList, supplierOption, deleteReceivingReport, setLoading, printRrPDF, printRtvPDF,
  } = props;
  const columns = [
    {
      key: 'removeRR',
      align: 'center',
      render: (record) => (
        <Tooltip
          title="Delete record"
          placement="right"
        >
          <Button
            size="small"
            className="red-cl"
            icon="delete"
            onClick={() => Confirm(deleteReceivingReport, null, record.id, setLoading)}
          />
        </Tooltip>
      ),
    },
    {
      key: 'printRR',
      align: 'center',
      render: (record) => (
        <Tooltip
          title="Print record"
          placement="right"
        >
          <Button
            size="small"
            className="bl-cl"
            icon="printer"
            onClick={() => printRrPDF(record.id, setLoading)}
          />
        </Tooltip>
      ),
    },
    {
      key: 'printRTV',
      title: 'RTV',
      align: 'center',
      render: (record) => {
        if (record.status === 'NO REJECT') return <Icon className="bl-cl" type="stop" />
        return (
          <Tooltip
            title="Print rtv"
            placement="right"
          >
            <Button
              size="small"
              className="bl-cl"
              icon="printer"
              onClick={() => printRtvPDF(record.id, setLoading)}
            />
          </Tooltip>
        )
      },
    },
    {
      key: 'status',
      title: 'Status',
      render: (data) => {
        let color = '#c0392b';
        if (data.status === 'NO REJECT') color = '#27ae60';
        return <div style={{ color, fontWeight: 750 }}>{data.status}</div>
      },
      align: 'center',
      filters: [
        {
          text: 'NO REJECT',
          value: 'NO REJECT',
        },
        {
          text: 'W/ REJECT',
          value: 'W/ REJECT',
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => {
        const A = a.status ? a.status : '';
        const B = b.status ? b.status : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'supplier',
      title: 'Supplier',
      dataIndex: 'supplier',
      align: 'center',
      filters: supplierOption.map((supplier) => ({
        text: supplier.supplierName,
        value: supplier.id,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => {
        const A = a.supplier ? a.supplier : '';
        const B = b.supplier ? b.supplier : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'rrNumber',
      title: 'Receiving Report',
      dataIndex: 'rrNumber',
      align: 'center',
      sorter: (a, b) => {
        const A = a.rrNumber ? a.rrNumber : '';
        const B = b.rrNumber ? b.rrNumber : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'poNum',
      title: 'Purchase Order',
      dataIndex: 'poNum',
      align: 'center',
      sorter: (a, b) => {
        const A = a.poNum ? a.poNum : '';
        const B = b.poNum ? b.poNum : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'materialSpecification',
      title: 'Material Specification',
      dataIndex: 'materialSpecification',
      align: 'center',
      sorter: (a, b) => {
        const A = a.materialSpecification ? a.materialSpecification : '';
        const B = b.materialSpecification ? b.materialSpecification : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'invoice',
      title: 'Invoice',
      dataIndex: 'invoice',
      align: 'center',
      sorter: (a, b) => {
        const A = a.invoice ? a.invoice : '';
        const B = b.invoice ? b.invoice : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'dr',
      title: 'D.R No.',
      dataIndex: 'dr',
      align: 'center',
      sorter: (a, b) => {
        const A = a.dr ? a.dr : '';
        const B = b.dr ? b.dr : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      align: 'center',
      sorter: (a, b) => {
        const A = a.date ? a.date : null;
        const B = b.date ? b.date : null;
        return moment(A).unix() - moment(B).unix();
      },
    },
    {
      key: 'quantity',
      title: 'Qty.',
      dataIndex: 'quantity',
      align: 'center',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      key: 'inspectedQty',
      title: 'Inspected Qty.',
      dataIndex: 'inspectedQty',
      align: 'center',
      sorter: (a, b) => a.inspectedQty - b.inspectedQty,
    },
    {
      key: 'receivedQty',
      title: 'Received Qty.',
      dataIndex: 'receivedQty',
      sorter: (a, b) => a.receivedQty - b.receivedQty,
      align: 'center',
    },
    {
      key: 'remarks',
      title: 'Remarks',
      dataIndex: 'remarks',
      align: 'center',
    },
  ]

  return (
    <Table
      size="small"
      dataSource={rrList}
      columns={columns}
      bodyStyle={{
        fontSize: 12,
        overflowX: 'auto',
      }}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '25', '50'],
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
        position: 'both',
      }}
      rowKey="id"
      bordered
    />
  )
}
ReceivingReportTable.propTypes = {
  rrList: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierOption: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteReceivingReport: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  printRrPDF: PropTypes.func.isRequired,
  printRtvPDF: PropTypes.func.isRequired,
}
export default ReceivingReportTable
