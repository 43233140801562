import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Button,
  Table,
} from 'antd';

const PurchaseOrderTable = (props) => {
  const {
    poList,
    supplierOption,
    openModal,
  } = props;
  const columns = [
    {
      key: 'viewPurchaseOrderInvoice',
      width: '2%',
      align: 'center',
      render: (data) => (
        <Tooltip
          placement="right"
          title="View invoice/s"
        >
          <Button
            size="small"
            icon="bars"
            onClick={() => openModal(data, 'invoice')}
            className="bl-cl"
          />
        </Tooltip>
      ),
    },
    {
      key: 'viewPurchaseOrderItems',
      width: '2%',
      align: 'center',
      render: (data) => (
        <Tooltip
          placement="right"
          title="View purchase order items"
        >
          <Button
            size="small"
            icon="profile"
            onClick={() => openModal(data, 'item')}
            className="bl-cl"
          />
        </Tooltip>
      ),
    },
    {
      key: 'supplier',
      title: 'Supplier',
      dataIndex: 'supplier',
      align: 'center',
      filters: supplierOption.map((supplier) => ({
        text: supplier.supplierName,
        value: supplier.id,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => {
        const A = a.supplier ? a.supplier : '';
        const B = b.supplier ? b.supplier : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'poNum',
      title: 'Purchase Order',
      dataIndex: 'poNum',
      align: 'center',
      sorter: (a, b) => {
        const A = a.poNum ? a.poNum : '';
        const B = b.poNum ? b.poNum : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'prNumbers',
      title: 'Purchase Request',
      dataIndex: 'prNumbers',
      align: 'center',
      sorter: (a, b) => {
        const A = a.prNumbers ? a.prNumbers : '';
        const B = b.prNumbers ? b.prNumbers : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'total',
      title: 'Delivered/ Quantity',
      align: 'center',
      render: (data) => `${data.quantityDelivered}/${data.totalPoQuantity}`,
    },
    {
      key: 'currency',
      title: 'Currency',
      dataIndex: 'currency',
      filters: [
        {
          text: 'USD',
          value: 'USD',
        },
        {
          text: 'PHP',
          value: 'PHP',
        },
      ],
      onFilter: (value, record) => record.currency.indexOf(value) === 0,
      sorter: (a, b) => {
        const A = a.currency ? a.currency : '';
        const B = b.currency ? b.currency : '';
        return A.localeCompare(B);
      },
      align: 'center',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      sorter: (a, b) => {
        const A = a.date ? a.date : null;
        const B = b.date ? b.date : null;
        return moment(A).unix() - moment(B).unix();
      },
      align: 'center',
    },
  ]

  return (
    <Table
      rowKey="id"
      dataSource={poList}
      columns={columns}
      size="small"
      bodyStyle={{
        fontSize: 12,
        overFlowX: 'auto',
      }}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '25', '50'],
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
        position: 'both',
      }}
      bordered
    />
  )
}
PurchaseOrderTable.propTypes = {
  poList: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierOption: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
}
export default PurchaseOrderTable
